import React, { useEffect, useContext } from "react"
import { useTable, useGlobalFilter} from 'react-table'
import { useToasts } from 'react-toast-notifications'

import {  TableActionButton, CellContainer, GreenSignal } from "./index.styled"

import { GetTableValuesContext } from '../../../../context/CategoryContext'

import useCategory from "../../../../hooks/useCategory"

import { GlobalFilter } from "./GlobalFilter"

const CategoryTable = () => {
  const { addToast } = useToasts()

  const { categories, setUpdateModalIsOpen, setCategoryId } = useContext(GetTableValuesContext)
  const { getTableCategories } = useCategory(addToast)
  
  useEffect(() => {
    getTableCategories()
  }, [])

  const data = React.useMemo(() => categories, [categories])

  const columns = React.useMemo(() => [
    {
      Header: 'Label',
      accessor: 'label',
    },{
      Header: 'Code',
      accessor: 'code', // accessor is the "key" in the data
    },{
      Header: 'Popular',
      accessor: 'popular',
      Cell: ({row}) => {
        return <CellContainer>
          {row.original.popular && <GreenSignal />}
        </CellContainer> 
      }
    },{
      Header: 'Action',
      Cell: ({row}) => {
        return <TableActionButton onClick={() => {
          setCategoryId(row.original.id)
          setUpdateModalIsOpen(true)
      }}>Edit</TableActionButton>
      }
    }
  ], [categories])

  const { getTableProps, getTableBodyProps, rows, state, setGlobalFilter, headerGroups, prepareRow } = useTable({ columns, data }, useGlobalFilter)

  const { globalFilter } = state

  return <>
    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
    <table {...getTableProps()} className="content-table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  </>
  
  
}

export default CategoryTable