import React, { useEffect, useContext } from 'react'
import { Redirect, Route } from "react-router-dom"

import { AuthContext } from '../context/AuthContext'

const PublicRoute = ({ 
    component: Component, 
    ...rest 
}) => {
    const { authState, admin } = useContext(AuthContext)
    
    useEffect(() => {
    }, [authState])

    return <Route 
            {...rest}
            component={(props) => {
                if(authState === 'loading' || authState === 'init') return <h1>Loading..</h1> 

                if(authState === 'loaded'){
                    return !admin ? <Component {...rest} {...props}/>
                     :  <Redirect to={{
                                    pathname: '/stores',
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                }
            }}
        />
}

export default PublicRoute
