import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'
import { useQuery, useMutation } from 'react-query'
import toast from "react-hot-toast"

import { InputWrapper, Input, DateWrapper, Form, DropDownWrapper, Left, Right, CheckBoxWrapper } from './updateLinkModal.styled'

import useLink from '../../../../hooks/useDealmelaLink'
import { warehouseClient } from '../../../../utils/axios-utils'

import { GetTableValuesContext } from '../../../../context/LinkContext'
import { queryClient } from '../../../../App'


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '30%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const partnerNames = [
  {label: 'Home Page', value: 'home'},
  {label: 'Vcommission', value: 'vcom'},
  {label: 'Admitad', value: 'admitad'},
  {label: 'Affnet Media', value: 'affnet'},
  {label: 'Optimise Media', value: 'optimise'}
]

const fetchLink = async (linkId) => {
  const config = {
    url: `/dmlink/one/${linkId}`,
    method: "GET"
  }

  return warehouseClient(config)
}

const UpdateLink = (data) => {
  const config = {
    url: "/dmlink/update",
    method: "POST",
    data
  }

  return warehouseClient(config)
}

const UpdateLinkModal = ({ updateModalIsOpen, setUpdateModalIsOpen }) => {
  const { addToast } = useToasts()

  const [selectedStore, setSelectedStore] = useState()
  const [selectedPartner, setSelectedPartner] = useState()

  const {  register, handleSubmit, formState: { errors }, setValue, control } = useForm()

  const { linkId } = useContext(GetTableValuesContext)

  const { isLoading, error, data: link, isSuccess } = useQuery(
    ["onelink", linkId],
    () => fetchLink(linkId),
    {
      refetchOnMount: true,
      retry: false,
      refetchOnWindowFocus: false
    }
  )

  let toastId;

  const { mutate: onSubmit } = useMutation(UpdateLink, {
    onSuccess: (data) => {
      toast.success(data?.message)
      toast.dismiss(toastId)
      queryClient.refetchQueries(['tablelinks'])
    },
    onError: (err) => {
      toast.dismiss(toastId)
    },
    onMutate: () => {
      toastId = toast.loading("Updating link")
    }
  })

  useEffect(() => {
    if(link) {
      const { id, storeName, storeCode, partnerName, partnerCode, homeUrl, affiliateUrl, active, mustPath, ratio } = link

      setValue("id", id)
      setValue("storeName", storeName)
      setValue("storeCode", storeCode)
      setValue("partnerName", partnerName)
      setValue("partnerCode", partnerCode)
      setValue("homeUrl", homeUrl)
      setValue("affiliateUrl", affiliateUrl)
      setValue('active', active)
      setValue('mustPath', mustPath)
      setValue('ratio', ratio)
    }
  }, [link])

  return <Modal
            isOpen={updateModalIsOpen}
            onRequestClose={() => {setUpdateModalIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Link"
          >
            <h2>Update Link</h2>
            
              {
                isLoading ? <p>Loading...</p> : error ? <p>Error when fetching</p> : <Form onSubmit={handleSubmit(onSubmit)}>
                 <InputWrapper>
                      <Input 
                        disabled
                        {...register("id", {
                          required: "Enter a link id"
                        })}
                      />
                      {errors.id && <p>{errors.id.message}</p>}
                  </InputWrapper>
                  <DropDownWrapper>
                      <Left>
                          <Input 
                            placeholder="Store Name*" 
                            {...register("storeName", {
                              required: "Enter Store name"
                            })}
                          />
                          {errors.storeName && <p>{errors.storeName.message}</p>}
                      </Left>
                      <Right>
                          <Input 
                            disabled
                            placeholder="Store Code*" 
                            {...register("storeCode", {
                              required: "Enter Store code"
                            })}
                          />
                          {errors.storeCode && <p>{errors.storeCode.message}</p>}
                      </Right>
                  </DropDownWrapper>
  
                  <DropDownWrapper>
                      <Left>
                          <Input 
                            disabled
                            placeholder="Partner Name*" 
                            {...register("partnerName", {
                              required: "Enter Partner name"
                            })}
                          />
                          {errors.partnerName && <p>{errors.partnerName.message}</p>}
                      </Left>
                      <Right>
                          <Input 
                            disabled
                            placeholder="Partner Code*" 
                            {...register("partnerCode", {
                              required: "Enter Partner code"
                            })}
                          />
                          {errors.partnerCode && <p>{errors.partnerCode.message}</p>}
                      </Right>
                  </DropDownWrapper>
  
                  <InputWrapper>
                      <Input 
                        placeholder="Home URL*" 
                        {...register("homeUrl", {
                          required: "Enter Url"
                        })}
                        autoComplete="off"
                      />
                      {errors.homeUrl && <p>{errors.homeUrl.message}</p>}
                  </InputWrapper>
  
                  <InputWrapper>
                      <Input 
                        placeholder="Affiliate URL*" 
                        {...register("affiliateUrl", {
                          required: "Enter Affiliate Url"
                        })}
                        autoComplete="off"
                      />
                      {errors.affiliateUrl && <p>{errors.affiliateUrl.message}</p>}
                  </InputWrapper>
  
                  <InputWrapper>
                      <Input 
                        placeholder="Cart Page (optional)" 
                        {...register("mustPath")}
                      />
                      {errors.mustPath && <p>{errors.mustPath.message}</p>}
                  </InputWrapper>
  
                  <InputWrapper>
                      <Input 
                        placeholder="Ratio (optional)" 
                        {...register("ratio")}
                      />
                      {errors.ratio && <p>{errors.ratio.message}</p>}
                  </InputWrapper>
  
                  <CheckBoxWrapper>
                      <div>
                        <input type="checkbox" {...register("active")}/>
                        <label>Active</label>  
                      </div> 
                  </CheckBoxWrapper>
  
                  <input value="Update" type="submit"/>
                </Form>
              }
            
          </Modal>
}

export default UpdateLinkModal
