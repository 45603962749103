import styled from "styled-components"

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  
  h1{
    color:${props => props.theme.colors.darkGrey};
    width: 80%;
  }

  div {
    width: 17%;
    display: flex;
    justify-content: space-between;
  }
`;

export const TableContainer = styled.div`
  margin-top: 2rem;
`;
