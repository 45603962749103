import React, { useEffect, useContext } from 'react'
import { Redirect, Route } from "react-router-dom"

import { AuthContext } from '../context/AuthContext'

import Layout from '../Layout'

const PrivateRoute = ({ 
    component: Component, 
    ...rest 
}) => {

    const { authState, admin } = useContext(AuthContext)

    useEffect(() => {
    }, [authState])

    return <Route 
            {...rest}
            component={(props) => {
                if(authState === 'loading' || authState === 'init') return <h1>Loading..</h1> 

                if(authState === 'loaded'){
                    return admin ? <Layout><Component {...rest} {...props}/></Layout> :  <Redirect to={{
                                    pathname: '/login',
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                }
            }}
        />
}

export default PrivateRoute
