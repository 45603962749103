import axios from "axios"
import { useContext } from 'react'

import { GetBannerImagesContext } from '../context/BannerContext'
import Endpoints from '../config/endpoints'

const { PostMainBannerEndpoint, MainBannerTableEndpoint, GetHomeMainBannerByIdEndpoint, UpdateHomeMainBannerEndpoint,
  PostBottomBannerEndpoint, BottomBannerTableEndpoint, GetHomeBottomBannerByIdEndpoint, UpdateHomeBottomBannerEndpoint } = Endpoints

const useBanner = (addToast) => {

  const { setMainBanners, setMainBanner, setBottomBanners, setBottomBanner } = useContext(GetBannerImagesContext)

  const PostMainBanner = async (data) => {
    try {
      data = {
        ...data,
        position: parseInt(data.position)
      }

      const response = await axios({
        method: 'POST',
        url: PostMainBannerEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })
      
      MainBannerTable()
      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
    }catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const MainBannerTable = async (page) => {
    try {
      const response = await axios({
        method: 'GET',
        url: MainBannerTableEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      const banners = response.data.banners

      setMainBanners(banners)
    } catch(err) {

    }
  }

  const GetHomeMainBannerById = async (bannerId) => {
    try {
      if(!bannerId) return;

      const response = await axios({
        method: 'GET',
        url: GetHomeMainBannerByIdEndpoint + bannerId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setMainBanner(response.data)
    } catch(err) {

    }
  }

  const UpdateHomeMainBanner = async (data) => {
    try {

      data = {
        ...data,
        position: parseInt(data.position)
      }

      const response = await axios({
        method: 'POST',
        url: UpdateHomeMainBannerEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      MainBannerTable()
      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
    }catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const PostBottomBanner = async (data) => {
    try {
      data = {
        ...data,
        position: parseInt(data.position)
      }

      const response = await axios({
        method: 'POST',
        url: PostBottomBannerEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })
      
      BottomBannerTable()
      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
    }catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const BottomBannerTable = async (page) => {
    try {
      const response = await axios({
        method: 'GET',
        url: BottomBannerTableEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      const banners = response.data.banners

      setBottomBanners(banners)
    } catch(err) {

    }
  }

  const GetHomeBottomBannerById = async (bannerId) => {
    try {
      if(!bannerId) return;

      const response = await axios({
        method: 'GET',
        url: GetHomeBottomBannerByIdEndpoint + bannerId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setBottomBanner(response.data)
    } catch(err) {

    }
  }

  const UpdateHomeBottomBanner = async (data) => {
    try {

      data = {
        ...data,
        position: parseInt(data.position)
      }

      const response = await axios({
        method: 'POST',
        url: UpdateHomeBottomBannerEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      BottomBannerTable()
      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
    }catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return { PostMainBanner, MainBannerTable, GetHomeMainBannerById, UpdateHomeMainBanner, PostBottomBanner, BottomBannerTable, GetHomeBottomBannerById, UpdateHomeBottomBanner }
}

export default useBanner