import React, { useEffect, useState } from "react"
import { ToastProvider, useToasts } from 'react-toast-notifications'
import axios from "axios"

import { GetTableValuesContext } from '../../context/LinkContext'
import { Head, AddBtn, TableContainer } from './index.styled'

import AddLinkModal from './components/Modal/addLinkModal'
import UpdateLinkModal from "./components/Modal/updateLinkModal"
import GenerateLinkModal from "./components/Modal/generateLinkModal"

import LinksTable from "./components/querytable/LinkTable"

import { statics } from '../../config/static'
const { LINK_PAGE_SIZE } = statics

const LinkPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [links, setLinks] = useState([])

  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [generateModalIsOpen, setGenerateModalIsOpen] = useState(false)

  const [linkId, setLinkId] = useState('')
  const [link, setLink] = useState(null)

  // async logic
  const [pageCount, setPageCount] = useState(0)
  const [pageSize, setPageSize] = useState(LINK_PAGE_SIZE)

  return <div>
    <GetTableValuesContext.Provider value={{ links, setIsOpen, setLinks, setUpdateModalIsOpen, linkId, setLinkId, link, setLink, pageCount, setPageCount, pageSize, generateModalIsOpen, setGenerateModalIsOpen }}>
      <ToastProvider placement="bottom-right">
        <Head>
          <h1>Links</h1>
          <div>
            <AddBtn onClick={() => {
              setIsOpen(true)
            }}>Add Link</AddBtn>
          </div>
          <AddLinkModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
          { updateModalIsOpen && <UpdateLinkModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen} />}
          <GenerateLinkModal modalIsOpen={generateModalIsOpen} setIsOpen={setGenerateModalIsOpen}/>
        </Head>

        <TableContainer>
          <LinksTable /> 
        </TableContainer>
      </ToastProvider>
    </GetTableValuesContext.Provider>
  </div>
}

export default LinkPage
