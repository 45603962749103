import React, { useState, useEffect } from "react"
import { ToastProvider, useToasts } from 'react-toast-notifications'

import { Head, AddBtn, TableContainer, Pagination, Prev, Next, FilterMenu, AlLink } from './index.styled'

import { GetTableValuesContext } from '../../context/StoreContext'

import AddStoreModal from "./components/Modal/addStoreModal"
import UpdateStoreModal from "./components/Modal/updateStoreModal"
import StoresTable from "./components/Table"

import useCategory from "../../hooks/useCategory"


const StorePage = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [page, setPage] = useState(1)

  const [storeId, setStoreId] = useState('')
  const [store, setStore] = useState(null)
  const [stores, setStores] = useState([])
  const [storeLastPage, setStoreLastPage] = useState(0)

  const { addToast } = useToasts()

  const { GetCategoryNames, categoryNames } = useCategory(addToast)

  // useEffect(() => {
  // }, [page])

  useEffect(() => {
    GetCategoryNames()
  }, [])

  return <div>
    <GetTableValuesContext.Provider value={{ stores, setStoreLastPage, setStores, page, setPage, setIsOpen, setUpdateModalIsOpen, storeId, setStoreId, store, setStore, categoryNames }}>
      <ToastProvider placement="bottom-right">
        <Head>
          <h1>Stores</h1>
          <AddBtn onClick={() => {setIsOpen(true)}}>Add Store</AddBtn>
          <AddStoreModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
          <UpdateStoreModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen}/>
        </Head>

        <TableContainer>
          <StoresTable />
        </TableContainer>
      </ToastProvider>
    </GetTableValuesContext.Provider>
  </div>
}

export default StorePage