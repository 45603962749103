import React, { useEffect, useState } from "react"
import { useToasts } from 'react-toast-notifications'
import { Head, AddBtn, TableContainer, Pagination, Prev, Next } from './index.styled'

import AddCouponModal from "./components/Modal/addCouponModal"
import UpdateCouponModal from "./components/Modal/updateCouponModal"
import UploadCouponsModal from "./components/Modal/uploadCouponsModal"
import CouponsTable from "./components/Table"

import { GetTableValuesContext } from '../../context/CouponContext'

import useStore from "../../hooks/useStore"
import useCategory from "../../hooks/useCategory"

import { statics } from '../../config/static'

const { COUPON_PAGE_SIZE } = statics

const CouponPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [couponId, setCouponId] = useState('')
  const [coupon, setCoupon] = useState(null)
  const [page, setPage] = useState(1)
  const [coupons, setCoupons] = useState([])
  const [couponLastPage, setCouponLastPage] = useState(0)
  const [filterApplied, setFilterApplied] = useState(false)

  // async logic
  const [pageCount, setPageCount] = useState(0)
  const [pageSize, setPageSize] = useState(COUPON_PAGE_SIZE)


  const { addToast } = useToasts()

  const { GetStoreNames, storeNames } = useStore(addToast)
  const { GetCategoryNames, categoryNames } = useCategory(addToast)
  
  useEffect(() => {
  }, [page])


  useEffect(() => {
    GetStoreNames()
    GetCategoryNames()
  }, [])

  return <div>
    <GetTableValuesContext.Provider value={{ coupons, setCouponLastPage, setCoupons, page, setIsOpen, setUpdateModalIsOpen, couponId, setCouponId, coupon, setCoupon, filterApplied, setFilterApplied, storeNames, categoryNames, pageCount, pageSize, setPageCount }}>
      <Head>
        <h1>Coupons</h1>
        <div>
          <AddBtn onClick={() => {
            setIsOpen(true)
          }}>Add Coupon</AddBtn>
          <AddBtn onClick={() => {setUploadModalOpen(true)}}>Upload Coupons</AddBtn>
        </div>
          <AddCouponModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
          <UpdateCouponModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen}/>
          <UploadCouponsModal uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen}/>
      </Head>

      <TableContainer>
        <CouponsTable />
      </TableContainer>
    </GetTableValuesContext.Provider>
  </div>
}

export default CouponPage
