import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { GetTableValuesContext } from '../../../../context/LinkContext'
import { useMutation } from 'react-query'
import { warehouseClient } from '../../../../utils/axios-utils'
import toast from "react-hot-toast"

import { InputWrapper, Input, Form, TArea } from './generateLinkModal.styled'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '30%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const GenerateLink = (data) => {
  const config = {
    url: "/dmlink/deeplink",
    method: "POST",
    data
  }

  return warehouseClient(config)
}

const GenerateLinkModal = ({ modalIsOpen, setIsOpen }) => {
  const [deepLink, setDeepLink] = useState('')
  const [shortLink, setShortLink] = useState('')
  const [linkType, setLinkType] = useState('short')

  const {  register, handleSubmit, formState: { errors }, setValue, control } = useForm()

  const { linkId } = useContext(GetTableValuesContext)

  useEffect(() => {
    if(linkId) {
      setValue("linkId", linkId)
      setValue("fullUrl", '')
      setDeepLink("")
      setShortLink("")
    }
  }, [linkId])

  let toastId;

  const { mutate: onSubmit } = useMutation(GenerateLink, {
    onSuccess: (data) => {
      setDeepLink(data?.deepLink)
      setShortLink(data?.shortUrl)
      toast.dismiss(toastId)
    },
    onError: (err) => {
      toast.dismiss(toastId)
    },
    onMutate: () => {
      toastId = toast.loading("Updating link")
    }
  })

  const onRadioChange = (event) => {
    setLinkType(event.target.value)
  }

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Generate Link"
          >
            <h2>Generate Deep Link</h2>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputWrapper>
                  <Input 
                    disabled
                    {...register("linkId", {
                      required: "Enter a link id"
                    })}
                  />
                  {errors.linkId && <p>{errors.linkId.message}</p>}
              </InputWrapper>

              <InputWrapper>
                  <TArea 
                    placeholder='Paste full URL here'
                    {...register("fullUrl", {
                      required: "Enter Full page url"
                    })}
                  />
                  {errors.fullUrl && <p>{errors.fullUrl.message}</p>}
              </InputWrapper>

              <input value="Generate" type="submit"/>
            </Form>

            <InputWrapper>
                  <TArea 
                    placeholder='Deep link here'
                    value={linkType === 'full' ? deepLink : shortLink}
                  />
            </InputWrapper>

            <div onChange={onRadioChange}>
              <input type="radio" value="short" checked={linkType === "short"} /> Short link
              <input type="radio" value="full"  checked={linkType === "full"} style={{ marginLeft: '1rem'}}/> Full link
            </div>
          </Modal>
}

export default GenerateLinkModal
