import React, { useEffect} from 'react'
import Modal from 'react-modal'
import { useForm } from "react-hook-form"
import { useToasts } from 'react-toast-notifications'
import { uuid } from 'uuidv4'

import { InputWrapper, Input, Form, CheckBoxWrapper } from './addBottomBannerModal.styled'

import useBanner from '../../../../hooks/useBanner'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '35%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const AddBannerModal = ({ modalIsOpen, setIsOpen }) => {
  const { addToast } = useToasts()

  const { register, handleSubmit, formState: { errors }, setValue } = useForm()

  const { PostBottomBanner } = useBanner(addToast)


  useEffect(() => {
    setValue("id", `DM-${uuid()}`)
  }, [modalIsOpen])

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Banner"
          >
            <h2>Add Bottom Banner</h2>
              <Form onSubmit={handleSubmit(PostBottomBanner)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a banner id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Image URL*"
                      {...register("imageUrl", {
                        required: "Paste image url here"
                      })}
                    />
                    {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Redirect URL*"
                      {...register("redirectUrl", {
                        required: "Enter Redirect url here"
                      })}
                    />
                    {errors.redirectUrl && <p>{errors.redirectUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      type="number"
                      placeholder="Banner position*"
                      {...register("position", {
                        required: "Enter position"
                      })}
                    />
                    {errors.position && <p>{errors.position.message}</p>}
                </InputWrapper>

                <CheckBoxWrapper>
                  <div>
                    <input type="checkbox" {...register("active")}/>
                    <label>Active</label>
                  </div>  
                </CheckBoxWrapper>

                <input value="Add" type="submit"/>
              </Form>
          </Modal>
}

export default AddBannerModal


