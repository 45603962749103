import React, { useEffect, useContext} from 'react'
import Modal from 'react-modal'
import { useForm, Controller } from "react-hook-form"
import DatePicker from 'react-datetime-picker'
import { useToasts } from 'react-toast-notifications'
import urlSlug from 'url-slug'
import Select from 'react-select'

import { InputWrapper, Input, DateWrapper, Form, DropDownWrapper, Left, Right, CheckBoxWrapper } from './updateDealModal.styled'

import useStore from '../../../../hooks/useStore'
import useCategory from '../../../../hooks/useCategory'
import useDeal from '../../../../hooks/useDeal'

import { GetTableValuesContext } from '../../../../context/DealContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '80%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const customStylesSelect = {
  menu: (provided) => ({
    ...provided,
    maxHeight: '100px',
    overflow: 'auto'
  }),
  menuList: () => ({
    maxHeight: '100px'
  })
}

const UpdateDealModal = ({ updateModalIsOpen, setUpdateModalIsOpen }) => {
  const { addToast } = useToasts()

  const { control, register, handleSubmit, formState: { errors }, setValue} = useForm()

  const { GetStoreNames, storeNames } = useStore()
  const { GetCategoryNames, categoryNames } = useCategory(addToast)
  const { UpdateDeal, GetDealById } = useDeal(addToast)


  const { dealId, deal } = useContext(GetTableValuesContext)

  useEffect(() => {
    GetDealById(dealId)
    GetStoreNames()
    GetCategoryNames()
  }, [dealId])

  useEffect(() => {
    if(deal) {
      const { id, title, slug, actualPrice, offerPrice, percentageOff, imageUrl, storeCode, categoryCode, store, category, expires, hot, redirectUrl } = deal


      setValue('id', id)
      setValue('title', title)
      setValue('slug', slug)
      setValue('actualPrice', actualPrice)
      setValue('offerPrice', offerPrice)
      setValue('percentageOff', percentageOff)
      setValue('imageUrl', imageUrl)
      setValue('redirectUrl', redirectUrl)
      setValue('storeCode', {
        label: store.name,
        value: storeCode
      })
      setValue('categoryCode', {
        label: category.label,
        value: categoryCode
      })
      setValue('expires', new Date(expires))
      setValue('hot', hot)
    }
   }, [deal])

   const handleTitleChange = (e) => {
    let thisSlug = urlSlug(e.target.value)
    setValue("slug",thisSlug)
  }

  return <Modal
            isOpen={updateModalIsOpen}
            onRequestClose={() => {setUpdateModalIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Update Deal"
          >
            <h2>Update Deal</h2>
            {!deal ? <p>Loading..</p> :            
              <Form onSubmit={handleSubmit(UpdateDeal)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a deal id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="Deal Title*" 
                    {...register("title", {
                      required: "Enter a deal title"
                    })}
                    onChange={handleTitleChange}
                  />
                  {errors.title && <p>{errors.title.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="URL Slug*" 
                    disabled
                    {...register("slug", {
                      required: "Slug required"
                    })}
                  />
                  {errors.slug && <p>{errors.slug.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input
                    type="number" 
                    placeholder="Actual Price*" 
                    {...register("actualPrice", {
                      required: "Enter actual price of the deal"
                    })} 
                  />
                  {errors.actualPrice && <p>{errors.actualPrice.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input
                    type="number" 
                    placeholder="Offer Price*" 
                    {...register("offerPrice", {
                      required: "Enter offer price of the deal"
                    })} 
                  />
                  {errors.offerPrice && <p>{errors.offerPrice.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input
                    type="number" 
                    placeholder="Offer Percentage*" 
                    {...register("percentageOff", {
                      required: "Offer percentage*"
                    })}
                  />
                  {errors.percentageOff && <p>{errors.percentageOff.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="Image URL*" 
                    {...register("imageUrl", {
                      required: "Paste an image URL"
                    })} 
                  />
                  {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Redirect URL*" 
                    {...register("redirectUrl", {
                      required: "Paste an redirect URL"
                    })} 
                  />
                  {errors.redirectUrl && <p>{errors.redirectUrl.message}</p>}
                </InputWrapper>

                <DropDownWrapper>
                    <Left>
                      <Controller
                        control={control}
                        name="storeCode"
                        {...register("storeCode", {
                          required: "Select a store"
                        })}
                        render={({field: {onChange, value}}) => (
                            <Select 
                              className="r-dropdown" 
                              options={storeNames}  
                              placeholder="Select Store" 
                              onChange={onChange} 
                              value={value}
                              isSearchable
                              styles={customStylesSelect}
                            />
                        )}
                    />
                    {errors.storeCode && <p>{errors.storeCode.message}</p>}
                    </Left>
                    <Right>
                      <Controller
                          control={control}
                          name="categoryCode"
                          {...register("categoryCode", {
                            required: "Select a Category"
                          })}
                          render={({field: {onChange, value}}) => (
                              <Select 
                                className="r-dropdown" 
                                options={categoryNames}  
                                placeholder="Select Category" 
                                onChange={onChange} 
                                value={value}
                                isSearchable
                                styles={customStylesSelect}
                              />
                          )}
                      />
                      {errors.categoryCode && <p>{errors.categoryCode.message}</p>}
                    </Right>
                </DropDownWrapper>

                <DateWrapper>
                  <label>Expires</label>
                  <Controller
                      control={control}
                      name="expires"
                      {...register("expires", {
                        required: "Select a expiry date"
                      })} 
                      render={({field: {onChange, value}}) => (
                          <DatePicker
                            className="react-date"
                            onChange={onChange}
                            value={value}
                          />
                      )}
                  />
                  {errors.expires && <p>{errors.expires.message}</p>}
                </DateWrapper>

                <CheckBoxWrapper>
                    <div>
                      <input type="checkbox" {...register("hot")}/>
                      <label>Hot</label>  
                    </div>
                </CheckBoxWrapper>

                <input value="Update" type="submit"/>
              </Form>}
          </Modal>
}

export default UpdateDealModal

