import { warehouseClient } from '../../../../utils/axios-utils'

export const fetchLinkData = async (page, pageSize) => {
  const offset = page * pageSize

  const config = {
    url: `/dmlink/all?offset=${offset}&limit=${pageSize}`,
    method: "GET"
  }

  return warehouseClient(config)
};
