import React, {useState, useEffect, useContext} from 'react'
import Modal from 'react-modal'
import { useForm, Controller } from "react-hook-form"
import DatePicker from 'react-datetime-picker'
import { useToasts } from 'react-toast-notifications'
import { uuid } from 'uuidv4'
import Select from 'react-select'
import urlSlug from 'url-slug'

import { InputWrapper, Input, DateWrapper, Form, DropDownWrapper, Left, Right, CheckBoxWrapper } from './addDealModal.styled'
import { Spinner } from '../../../../globalStyles'

import useDeal from '../../../../hooks/useDeal'

import { GetTableValuesContext } from '../../../../context/DealContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '80%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const customStylesSelect = {
  menu: (provided) => ({
    ...provided,
    maxHeight: '100px',
    overflow: 'auto'
  }),
  menuList: () => ({
    maxHeight: '100px'
  })
}

const AddDealModal = ({ modalIsOpen, setIsOpen }) => {
  const { addToast } = useToasts()
  let actualDate = new Date()
  const [currentDate, setCurrentDate] = useState(() => {
    const actualDate = new Date();
    return new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 4, 23, 59, 59);
  })

  const { control, register, handleSubmit, formState: { errors }, watch, setValue, getValues } = useForm()

  const { PostDeal, loading } = useDeal(addToast)

  const [selectedStore, setSelectedStore] = useState()
  const [selectedCategory, setSelectedCategory] = useState()


  const { storeNames, categoryNames } = useContext(GetTableValuesContext)

  useEffect(() => {
    setValue("id", `DM-${uuid()}`)
    setValue("title","")
    setValue("slug","")
    setValue("actualPrice",null)
    setValue("offerPrice",null)
    setValue("percentageOff","")
    setValue("redirectUrl","")
    setValue("productImage","")
    setValue('storeCode', null)
    setValue('categoryCode', null)
    setValue('expires', currentDate)
    setValue('hot', false)
  }, [modalIsOpen])

  const handleTitleChange = (e) => {
    let thisSlug = urlSlug(e.target.value)
    setValue("slug",thisSlug)
  }

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Deal"
          >
            <h2>Add Deal</h2>
            
              <Form onSubmit={handleSubmit(PostDeal)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a deal id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="Deal Title*" 
                    {...register("title", {
                      required: "Enter a deal title"
                    })}
                    onChange={handleTitleChange}
                  />
                  {errors.title && <p>{errors.title.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="URL Slug*" 
                    disabled
                    {...register("slug", {
                      required: "Slug required"
                    })}
                  />
                  {errors.slug && <p>{errors.slug.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input
                    type="number" 
                    placeholder="Actual Price*" 
                    {...register("actualPrice", {
                      required: "Enter actual price of the deal"
                    })} 
                  />
                  {errors.actualPrice && <p>{errors.actualPrice.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input
                    type="number" 
                    placeholder="Offer Price*" 
                    {...register("offerPrice", {
                      required: "Enter offer price of the deal"
                    })} 
                    disabled={!watch("actualPrice")}
                    onChange={(e) => {
                      const actualPrice = getValues("actualPrice")
                      const offerPrice = e.target.value

                      let divideRes = actualPrice / offerPrice
                      let res = Math.round(100 / divideRes)

                      setValue("percentageOff", 100 - res)
                    }} 
                  />
                  {errors.offerPrice && <p>{errors.offerPrice.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input
                    type="number" 
                    placeholder="Offer Percentage*" 
                    {...register("percentageOff", {
                      required: "Offer percentage*"
                    })}
                    disabled={!watch("offerPrice")} 
                  />
                  {errors.percentageOff && <p>{errors.percentageOff.message}</p>}
                </InputWrapper>

                {/* <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Image URL*" 
                    {...register("imageUrl", {
                      required: "Paste an image URL"
                    })} 
                  />
                  {errors.imageUrl && <p>{errors.imageUrl.message}</p>}
                </InputWrapper> */}

                <InputWrapper>
                  <Input 
                    autoComplete="off"
                    placeholder="Redirect URL*" 
                    {...register("redirectUrl", {
                      required: "Paste an redirect URL"
                    })} 
                  />
                  {errors.redirectUrl && <p>{errors.redirectUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    type="file" 
                    accept="image/*"
                    name="productImage"
                    {...register("productImage", {
                      required: "Upload an image"
                    })} 
                  />
                  {errors.productImage && <p>{errors.productImage.message}</p>}
                </InputWrapper>

                <DropDownWrapper>
                    <Left>
                      <Controller
                        control={control}
                        name="storeCode"
                        {...register("storeCode", {
                          required: "Select a store"
                        })}
                        onChange={(store) => {setSelectedStore(store)}}
                        value={selectedStore}
                        render={({field: {onChange, value}}) => (
                            <Select 
                              className="r-dropdown" 
                              options={storeNames}  
                              placeholder="Select Store" 
                              onChange={onChange} 
                              value={value}
                              isSearchable
                              styles={customStylesSelect}
                            />
                        )}
                    />
                    {errors.storeCode && <p>{errors.storeCode.message}</p>}
                    </Left>
                    <Right>
                      <Controller
                          control={control}
                          name="categoryCode"
                          {...register("categoryCode", {
                            required: "Select a Category"
                          })}
                          onChange={(category) => {setSelectedCategory(category)}}
                          value={selectedCategory}
                          render={({field: {onChange, value}}) => (
                              <Select 
                                className="r-dropdown" 
                                options={categoryNames}  
                                placeholder="Select Category" 
                                onChange={onChange} 
                                value={value}
                                isSearchable
                                styles={customStylesSelect}
                              />
                          )}
                      />
                      {errors.categoryCode && <p>{errors.categoryCode.message}</p>}
                    </Right>
                </DropDownWrapper>

                <DateWrapper>
                  <label>Expires</label>
                  <Controller
                      control={control}
                      name="expires"
                      {...register("expires", {
                        required: "Select a expiry date"
                      })} 
                      defaultValue={currentDate}
                      value={currentDate}
                      onChange={(date) => {setCurrentDate(date)}}
                      render={({field: {onChange, value}}) => (
                          <DatePicker
                            className="react-date"
                            onChange={onChange}
                            value={value}
                          />
                      )}
                  />
                  {errors.expires && <p>{errors.expires.message}</p>}
                </DateWrapper>

                <CheckBoxWrapper>
                    <div>
                      <input type="checkbox" {...register("hot")}/>
                      <label>Hot</label>  
                    </div>
                </CheckBoxWrapper>

                <input value={loading ? 'Adding' : 'Add'} type="submit"/>
              </Form>
            
          </Modal>
}

export default AddDealModal

