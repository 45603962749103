import React, { useEffect, useState } from "react"
import { useTable, useGlobalFilter} from 'react-table'
import { useToasts } from 'react-toast-notifications'
import axios from "axios"

import { Table, THead, TBody, TableRow } from "./index.styled"

import endpoints from "../../../../config/endpoints"
import { GlobalFilter } from './GlobalFilter'

const { GetNewsletterDataEndpoint } = endpoints

const NewslettersTable = () => {
  const [newsletters, setNewsletters] = useState([])
  const { addToast } = useToasts()

  const GetNewsletterData = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: GetNewsletterDataEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setNewsletters(response.data)
    } catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  useEffect(() => {
    GetNewsletterData()
  }, [])

  const data = React.useMemo(() => newsletters, [newsletters])

  const columns = React.useMemo(() => [
    {
      Header: 'Email',
      accessor: 'email', // accessor is the "key" in the data
    }
  ], [newsletters])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable({ columns, data }, useGlobalFilter)

  const { globalFilter } = state

  return <>
  <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
  <table {...getTableProps()} className="content-table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </TableRow>
          )
        })}
      </tbody>
    </table>
  </>
}

export default NewslettersTable