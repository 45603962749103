import { useState, useContext } from 'react'
import axios from "axios"

import Endpoints from '../config/endpoints'

import { GetTableValuesContext } from '../context/OfferContext'

const { PostOfferEndpoint, UpdateOfferEndpoint, UploadOffersEndpoint, GetTableOffersEndpoint, GetOfferByIdEndpoint } = Endpoints

const useOffer = (addToast) => {
  const [files, setFiles] = useState([])
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [selectedFilesLength, setSelectedFilesLength] = useState(0)

  const { setOffer,setOffers, setOfferLastPage } = useContext(GetTableValuesContext)

  const docsPerPage = 20

  const PostOffer = async (data) => {
    try {
      data = {
        ...data,
        score: parseInt(data.score),
        storeCode: data.storeCode.value,
        categoryCode: data.categoryCode.value
      }

      const response = await axios({
        method: 'POST',
        url: PostOfferEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableOffers()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const onSubmit = async () => {
    try {
      if(!files.length) {
        addToast("Select some files to upload", { 
          appearance: 'error',
          autoDismiss: true
        })
        return
      }
      
      const formData = new FormData()

      for(let i = 0; i < files.length; i++) {  
        formData.append('files', files[i]);
      }

      const response = await axios({
        method: 'POST',
        url: UploadOffersEndpoint,
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
        withCredentials: true,
        data: formData,
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(50)
        },
      })

      setUploadPercentage(100)
      setSelectedFilesLength(0)

      setTimeout(() => {
        setUploadPercentage(0)
      }, [100])

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableOffers()
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
      setUploadPercentage(100)
      setSelectedFilesLength(0)

      setTimeout(() => {
        setUploadPercentage(0)
      }, [100])
    }
  }

  const getTableOffers = async (page = 1) => {
    try {
      const response = await axios({
        method: 'POST',
        url: GetTableOffersEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ page })
      })

      const offers = response.data.offers

      if(offers.length > 0) {
        let parsedArray = offers.map((off) => {
          let resObj = {
            ...off,
            store: off.store.name,
            category: off.category.label
          }

          return resObj
        })

        setOffers(parsedArray)
        setOfferLastPage(Math.ceil(response.data.totalOffersCount / docsPerPage))
      }
    } catch(err) {

    }
  }

  const GetOfferById = async (offerId) => {
    try {
      if(!offerId) return;

      const response = await axios({
        method: 'GET',
        url: GetOfferByIdEndpoint + offerId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setOffer(response.data)
    } catch(err) {

    }
  }

  const UpdateOffer = async (data) => {
    try {
      data = {
        ...data,
        storeCode: data.storeCode.value,
        categoryCode: data.categoryCode.value
      }

      const response = await axios({
        method: 'POST',
        url: UpdateOfferEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableOffers()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return { PostOffer, UpdateOffer, GetOfferById, onSubmit,  setFiles, uploadPercentage, selectedFilesLength, setSelectedFilesLength, getTableOffers }
}

export default useOffer

