import React, {useState, useEffect, useContext} from 'react'
import Modal from 'react-modal'
import { useForm, Controller } from "react-hook-form"
import DatePicker from 'react-date-picker'
import { useToasts } from 'react-toast-notifications'
import { uuid } from 'uuidv4'
import Select from 'react-select'

import { InputWrapper, Input, DateWrapper, Form, DropDownWrapper, Left, Right, CheckBoxWrapper } from './addCouponModal.styled'

import useCoupon from '../../../../hooks/useCoupon'

import { GetTableValuesContext } from '../../../../context/CouponContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '75%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const customStylesSelect = {
  menu: (provided) => ({
    ...provided,
    maxHeight: '100px',
    overflow: 'auto'
  }),
  menuList: () => ({
    maxHeight: '100px'
  })
}

const AddCouponModal = ({ modalIsOpen, setIsOpen }) => {
  const { addToast } = useToasts()
  const [currentDate, setCurrentDate] = useState(new Date())

  const { control, register, handleSubmit, formState: { errors }, setValue } = useForm()

  const { PostCoupon } = useCoupon(addToast)

  const [selectedStore, setSelectedStore] = useState()
  const [selectedCategory, setSelectedCategory] = useState()

  const { storeNames, categoryNames} = useContext(GetTableValuesContext)

  useEffect(() => {
    setValue("id", `DM-${uuid()}`)
    setValue("gid", "")
    setValue("title", "")
    setValue("description", "")
    setValue("couponCode", "")
    setValue("score", "")
    setValue("detailDescription", "")
    setValue('storeCode', null)
    setValue('categoryCode', null)
    setValue('expires', new Date())
    setValue('popular', false)
  }, [modalIsOpen])

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Coupon"
          >
            <h2>Add Coupon</h2>
              <Form onSubmit={handleSubmit(PostCoupon)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a coupon id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Coupon GID*"
                      {...register("gid", {
                        required: "Enter a coupon GID"
                      })}
                    />
                    {errors.gid && <p>{errors.gid.message}</p>}
                </InputWrapper>
                
                <InputWrapper>
                  <Input 
                    placeholder="Coupon Title*" 
                    {...register("title", {
                      required: "Enter a coupon title"
                    })}
                  />
                  {errors.title && <p>{errors.title.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    placeholder="Coupon Description*" 
                    {...register("description", {
                      required: "Enter a coupon description"
                    })} 
                  />
                  {errors.description && <p>{errors.description.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="Coupon Code*" 
                    {...register("couponCode", {
                      required: "Enter a coupon code"
                    })} 
                  />
                  {errors.couponCode && <p>{errors.couponCode.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    type="number"
                    placeholder="Score*" 
                    {...register("score", {
                      required: "Enter a coupon score"
                    })} 
                  />
                  {errors.score && <p>{errors.score.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    placeholder="Coupon details(separated by ;)*" 
                    {...register("detailDescription", {
                      required: "Enter coupon details"
                    })} 
                  />
                  {errors.detailDescription && <p>{errors.detailDescription.message}</p>}
                </InputWrapper>

                <DropDownWrapper>
                    <Left>
                      <Controller
                        control={control}
                        name="storeCode"
                        {...register("storeCode", {
                          required: "Select a store"
                        })}
                        onChange={(store) => {setSelectedStore(store)}}
                        value={selectedStore}
                        render={({field: {onChange, value}}) => (
                            <Select 
                              className="r-dropdown" 
                              options={storeNames}  
                              placeholder="Select Store" 
                              onChange={onChange} 
                              value={value}
                              isSearchable
                              styles={customStylesSelect}
                            />
                        )}
                    />
                    {errors.storeCode && <p>{errors.storeCode.message}</p>}
                    </Left>
                    <Right>
                      <Controller
                          control={control}
                          name="categoryCode"
                          {...register("categoryCode", {
                            required: "Select a Category"
                          })}
                          onChange={(category) => {setSelectedCategory(category)}}
                          value={selectedCategory}
                          render={({field: {onChange, value}}) => (
                              <Select 
                                className="r-dropdown" 
                                options={categoryNames}  
                                placeholder="Select Category" 
                                onChange={onChange} 
                                value={value}
                                isSearchable
                                styles={customStylesSelect}
                              />
                          )}
                      />
                      {errors.categoryCode && <p>{errors.categoryCode.message}</p>}
                    </Right>
                </DropDownWrapper>

                <DateWrapper>
                  <label>Expires</label>
                  <Controller
                      control={control}
                      name="expires"
                      {...register("expires", {
                        required: "Select a expiry date"
                      })} 
                      defaultValue={currentDate}
                      value={currentDate}
                      onChange={(date) => {setCurrentDate(date)}}
                      render={({field: {onChange, value}}) => (
                          <DatePicker
                            className="react-date"
                            onChange={onChange}
                            value={value}
                          />
                      )}
                  />

                  {errors.expires && <p>{errors.expires.message}</p>}
                </DateWrapper>

                <CheckBoxWrapper>
                    <div>
                      <input type="checkbox" {...register("verified")}/>
                      <label>Verified</label>  
                    </div> 
                    <div>
                      <input type="checkbox" {...register("popular")}/>
                      <label>Popular</label>  
                    </div> 
                </CheckBoxWrapper>

                <input value="Add" type="submit"/>
              </Form>
            
          </Modal>
}

export default AddCouponModal


// onClick={() => {addToast("Successfully added coupon", { 
//   appearance: 'success',
//   autoDismiss: true
// })}}