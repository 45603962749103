import axios from "axios"
import { customToast } from "./toast-utils" 

import { statics } from '../config/static'

const { WAREHOUSE_URL } = statics

/* Common error handler for both clients */
const errorHandler = (err) => {
  if(err?.config?.url?.includes("/auth/api/getcurrentuser") && err?.code === "ERR_NETWORK"){
      return Promise.reject(err)
  }

  if(err.code === 'ERR_NETWORK'){
      customToast({
          type: 'error',
          message: "Connection lost"
      })
      return Promise.reject(err)
  }

  if(err?.response?.status === 401) {
      return Promise.reject(err)  
  }

  let errors = err?.response?.data?.errors || []

  errors.forEach(errObj => {
      customToast({
          type: 'error',
          message: errObj?.message || "Something went wrong"
      })
  })

  return Promise.reject(err)
}


/* App client */
export const warehouseClient = axios.create({ 
  baseURL: WAREHOUSE_URL,
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  },
  withCredentials: true
})

warehouseClient.defaults.headers.common["Cache-Control"] = "no-cache"
warehouseClient.defaults.headers.common["Pragma"] = "no-cache"

warehouseClient.interceptors.response.use((response) => {
  return response.data
}, errorHandler)