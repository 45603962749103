import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const HeaderDiv = styled.div`
  width: calc(100% - 200px);
  height: 80px;
  position: absolute;
  left: 200px;
  background-color: ${props => props.theme.colors.bg};
  box-shadow: 0 2px 4px rgb(50 50 93 / 10%);
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SideNav = styled.div`
  width: 200px;
  background-color: ${props => props.theme.colors.navyBlue};
  /* background: #331154;   */

  flex: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul{
    margin:0;
    padding: 0;
    width: 100%;
  }
`;

export const LI = styled.li`
    margin:0;
    padding: 0;
    list-style: none;
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    a{
        text-decoration: none;
        display: flex;
        color: white;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;  
        align-items: center;
    }
`


export const Content = styled.div`
    position: absolute;
    top: 80px;
    left: 200px;
    width: calc(100% - 200px);
    bottom: 0;
    padding: 2rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0.4em;
      border-radius: 1rem;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(165, 165, 165, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background-color: #393e46;
        outline: 1px solid ${props => props.theme.colors.darkGrey};;
    }
`;