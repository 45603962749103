import React, { useEffect, useState } from "react"
import { ToastProvider, useToasts } from 'react-toast-notifications'

import { Head, AddBtn, TableContainer, Pagination, Prev, Next } from './index.styled'

import AddOfferModal from "./components/Modal/addOfferModal"
import UpdateOfferModal from "./components/Modal/updateOfferModal"
import UploadOffersModal from "./components/Modal/uploadOffersModal"
import OffersTable from "./components/Table"

import { GetTableValuesContext } from '../../context/OfferContext'

import useStore from "../../hooks/useStore"
import useCategory from "../../hooks/useCategory"

const OfferPage = () => {
  const { addToast } = useToasts()

  const [modalIsOpen, setIsOpen] = useState(false)
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [offerId, setOfferId] = useState('')
  const [offer, setOffer] = useState(null)
  const [offers, setOffers] = useState([])
  const [offerLastPage, setOfferLastPage] = useState(0)

  const { GetStoreNames, storeNames } = useStore(addToast)
  const { GetCategoryNames, categoryNames } = useCategory(addToast)


  useEffect(() => {
    GetStoreNames()
    GetCategoryNames()
  }, [])

  return <div>
    <GetTableValuesContext.Provider value={{ offers, setOfferLastPage, setOffers, setIsOpen, setUpdateModalIsOpen, offerId, setOfferId, offer, setOffer, storeNames, categoryNames }}>
    <ToastProvider placement="bottom-right">
      <Head>
        <h1>Offers</h1>
        <div>
          <AddBtn onClick={() => {
            setIsOpen(true)
          }}>Add Offer</AddBtn>
          <AddBtn onClick={() => {setUploadModalOpen(true)}}>Upload Offers</AddBtn>
        </div>
          <AddOfferModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
          <UpdateOfferModal updateModalIsOpen={updateModalIsOpen} setUpdateModalIsOpen={setUpdateModalIsOpen}/>
          <UploadOffersModal uploadModalOpen={uploadModalOpen} setUploadModalOpen={setUploadModalOpen}/>
      </Head>

      <TableContainer>
        <OffersTable />
      </TableContainer>
      </ToastProvider>
    </GetTableValuesContext.Provider>
  </div>
}

export default OfferPage
