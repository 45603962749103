import axios from "axios"
import { useContext } from "react"
import Endpoints from '../config/warehouseEndpoints'

import { GetTableValuesContext } from '../context/LinkContext'

const { PostDMLinkEndpoint, GetDMLinkByIdEndpoint, GetDMLinksEndpoint, UpdateDMLinkEndpoint } = Endpoints

const useLink = (addToast) => {
  const { setLinks, setLink, setPageCount, pageSize } = useContext(GetTableValuesContext)
  
  const PostLink = async (data) => {
    try {
      data = {
        ...data,
        partnerCode: data.partnerCode.value,
        partnerName: data.partnerCode.label
      }

      const response = await axios({
        method: 'POST',
        url: PostDMLinkEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
      GetLinks()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetLinks = async (page = 1, offset) => {
    try {
      const response = await axios({
        method: 'POST',
        url: GetDMLinksEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ page, offset })
      })

      setLinks(response.data.links)
      const pageCt = Math.ceil(response.data.totalLinksCount / pageSize)
      setPageCount(pageCt)
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetLinkById = async (linkId) => {
    try {
      if(!linkId) return;

      const response = await axios({
        method: 'GET',
        url: GetDMLinkByIdEndpoint + linkId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setLink(response.data)
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const UpdateLink = async (data) => {
    try {
      const response = await axios({
        method: 'POST',
        url: UpdateDMLinkEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
      GetLinks()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return { PostLink, GetLinks, GetLinkById, UpdateLink }
}

export default useLink