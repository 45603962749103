import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from "styled-components"
import axios  from 'axios';
import { Toaster } from 'react-hot-toast'
import { ToastProvider } from 'react-toast-notifications'
import { QueryClientProvider, QueryClient } from 'react-query'

import { theme } from './theme'
import GlobalStyle from "./globalStyles";

import Login from './Pages/Login'
import CouponPage from './Pages/Coupons'
import OfferPage from './Pages/Offers'
import PermanentOfferPage from './Pages/Poffers'
import DealPage from './Pages/Deals'
import StorePage from './Pages/Stores'
import CategoryPage from './Pages/Categories'
import BannerPage from './Pages/Banners'
import NewsletterPage from './Pages/Newsletters'
import DMLinkPage from './Pages/DMLinks'

import { AuthContext } from './context/AuthContext'
import Endpoints from './config/endpoints'

import PrivateRoute from './HOC/PrivateRoute'
import PublicRoute from './HOC/PublicRoute'

const { CurrentAdmin } = Endpoints
export const queryClient = new QueryClient()

function App() {
  const [admin, setAdmin] = useState(null)
  const [authState, setAuthState] = useState('init')
  const [errorMessage, setErrorMessage] = useState('')

  const getAdmin = async () => {
    try {
      setAuthState('loading')
      const response = await axios({
        method: 'GET',
        url: CurrentAdmin,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setAdmin(response.data)
      setAuthState('loaded')
    } catch (err) {
      setAuthState('loaded')
    }
  }
  
  useEffect(() => {
    getAdmin()
  }, [])

  return <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <GlobalStyle />
              <ToastProvider placement="bottom-right">
                <Router>
                  <Switch>
                    <Redirect exact from="/" to="/login"/>
                    <AuthContext.Provider value={{admin, authState, setAdmin, setAuthState, errorMessage, setErrorMessage, getAdmin}}>
                        <PublicRoute
                          exact
                          path="/login"
                          component={Login}
                        />
                      {/* <PrivateRoute
                        exact
                        path="/dashboard"
                        component={UploadDeal}
                      /> */}

                      <PrivateRoute
                        exact
                        path="/coupons"
                        component={CouponPage}
                      />

                      <PrivateRoute
                        exact
                        path="/offers"
                        component={OfferPage}
                      />

                      <PrivateRoute
                        exact
                        path="/poffers"
                        component={PermanentOfferPage}
                      />

                      <PrivateRoute
                        exact
                        path="/deals"
                        component={DealPage}
                      />
                      
                      <PrivateRoute
                        exact
                        path="/stores"
                        component={StorePage}
                      />

                      <PrivateRoute
                        exact
                        path="/categories"
                        component={CategoryPage}
                      />

                      <PrivateRoute
                        exact
                        path="/banners"
                        component={BannerPage}
                      />

                      <PrivateRoute
                        exact
                        path="/dmlinks"
                        component={DMLinkPage}
                      />

                      <PrivateRoute
                        exact
                        path="/newsletters"
                        component={NewsletterPage}
                      />
                    </AuthContext.Provider>
                  </Switch>
                </Router>
              </ToastProvider>
              <Toaster />
            </QueryClientProvider>
      </ThemeProvider>
  
}

export default App;
