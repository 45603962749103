import { useContext } from "react"
import axios from "axios"

import { Container } from "./header.styled"

import { AuthContext } from "../../context/AuthContext"
import Endpoints from "../../config/endpoints"

const { LogoutAdmin } = Endpoints

const Header = () => {

  const { admin, setAuthState, setAdmin } = useContext(AuthContext)

  const handleLogout = async () => {
    try {
      setAuthState('loading')
      await axios({
        method: 'POST',
        url: LogoutAdmin,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setAdmin(null)
      setAuthState('loaded')
    } catch (err) {
      setAuthState('loaded')
    }
  }

  return <Container>
      <h2>{admin.name}</h2>
      <button onClick={handleLogout}>Logout</button>
  </Container>
}

export default Header