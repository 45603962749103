import React, { useEffect } from "react"
import { useTable, useGlobalFilter, usePagination} from 'react-table'
import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'

import { Pagination, Next, Prev, TableActionButton } from "./index.styled"

import { GetTableValuesContext } from '../../../../context/OfferContext'

import useOffer from "../../../../hooks/useOffer"

import { GlobalFilter } from "./GlobalFilter"

const OffersTable = () => {
  const { addToast } = useToasts()

  const { offers,  setUpdateModalIsOpen, setOfferId } = useContext(GetTableValuesContext)
  const { getTableOffers } = useOffer(addToast)
  
  useEffect(() => {
    getTableOffers()
  }, [])

  const data = React.useMemo(() => offers, [offers])

  const columns = React.useMemo(() => [{
      Header: 'Title',
      accessor: 'title', // accessor is the "key" in the data
    },{
      Header: 'Store',
      accessor: 'store',
    },{
      Header: 'Category',
      accessor: 'category',
    },{
      Header: 'Expires',
      accessor: 'expires',
      Cell: ({row}) => {
        return new Date(row.values.expires).toDateString() 
      }
    },{
      Header: 'Action',
      Cell: ({row}) => {
        return <TableActionButton onClick={() => {
          setOfferId(row.original.id)
          setUpdateModalIsOpen(true)
      }}>Edit</TableActionButton>
      }
    }
  ], [offers])

  const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, prepareRow, state, setGlobalFilter, setPageSize } = useTable({ columns, data }, useGlobalFilter, usePagination)

  const { globalFilter } = state

  useEffect(() => {setPageSize(50)}, [])

  return <>
    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
    <table {...getTableProps()} className="content-table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <Pagination>
      <Prev onClick={() => previousPage()}>Previous</Prev>
      <Next onClick={() => nextPage()}>Next</Next>
    </Pagination>
  </>
  
}

export default OffersTable