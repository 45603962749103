import React, { useEffect } from "react"
import { useTable, useFlexLayout, useGlobalFilter, useFilters, usePagination} from 'react-table'
import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'

import { Table, THead, TBody, TableRow, TableActionButton, CellContainer, GreenSignal, Pagination, Prev, Next } from "./index.styled"

import { GetTableValuesContext } from '../../../../context/StoreContext'

import useStore from "../../../../hooks/useStore"
import { GlobalFilter } from "./GlobalFilter"

const StoresTable = () => {
  const { addToast } = useToasts()

  const { stores,  setUpdateModalIsOpen, setStoreId } = useContext(GetTableValuesContext)
  const { getTableStores } = useStore(addToast)
  
  useEffect(() => {
      getTableStores()
  }, [])

  const data = React.useMemo(() => stores, [stores])

  const columns = React.useMemo(() => [{
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true
    },{
      Header: 'Code',
      accessor: 'code'
    },{
      Header: 'Heading',
      accessor: 'heading',
      disableGlobalFilter: true
    },{
      Header: 'Permanent Heading',
      accessor: 'pheading',
      disableGlobalFilter: true
    },{
      Header: 'Meta Title',
      accessor: 'metaTitle',
      disableGlobalFilter: true
    },{
      Header: 'Popular',
      accessor: 'popular',
      disableGlobalFilter: true,
      Cell: ({row}) => {
        return <CellContainer>
          {row.original.popular && <p>✅</p>}
        </CellContainer> 
      }
    },{
      Header: 'Action',
      disableGlobalFilter: true,
      Cell: ({row}) => {
        return <TableActionButton onClick={() => {
          setStoreId(row.original.id)
          setUpdateModalIsOpen(true)
      }}>Edit</TableActionButton>
      }
    }
  ], [stores])

  const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, prepareRow, state, setGlobalFilter, setPageSize } = useTable({ columns, data }, useGlobalFilter, usePagination)

  const { globalFilter } = state

  useEffect(() => {setPageSize(50)}, [])

  return <>
  <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
  <table className="content-table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {/* <div>{column.canFilter ? column.render('Filter'): <></>}</div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {/* {rows.length === 0 && <h3 style={{textAlign: "center", margin: '1rem 0'}}>No data found</h3>} */}
            {page.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination>
          <Prev onClick={() => previousPage()}>Previous</Prev>
          <Next onClick={() => nextPage()}>Next</Next>
        </Pagination>
    </>
}

export default StoresTable