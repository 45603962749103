import React, { useState, useEffect, useContext } from 'react'
import Modal from 'react-modal'
import { useForm, Controller } from "react-hook-form"
import DatePicker from 'react-date-picker'
import { useToasts } from 'react-toast-notifications'
import { uuid } from 'uuidv4'
import Select from 'react-select'
import slugify from 'slugify'
import toast from "react-hot-toast"
import { useMutation } from 'react-query'

import { InputWrapper, Input, DateWrapper, Form, DropDownWrapper, Left, Right, CheckBoxWrapper } from './addLinkModal.styled'

import { warehouseClient } from '../../../../utils/axios-utils'

import { queryClient } from '../../../../App'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '30%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const customStylesSelect = {
  menu: (provided) => ({
    ...provided,
    maxHeight: '100px',
    overflow: 'auto'
  }),
  menuList: () => ({
    maxHeight: '100px'
  })
}

const partnerNames = [
  {label: 'Vcommission', value: 'vcom'},
  {label: 'Admitad', value: 'admitad'},
  {label: 'Affnet Media', value: 'affnet'},
  {label: 'Optimise Media', value: 'optimise'}
]

const PostLink = (data) => {
  data = {
    ...data,
    partnerCode: data.partnerCode.value,
    partnerName: data.partnerCode.label
  }

  const config = {
    url: "/dmlink/create",
    method: "POST",
    data
  }

  return warehouseClient(config)
}

const AddLinkModal = ({ modalIsOpen, setIsOpen }) => {
  const { addToast } = useToasts()

  const [selectedStore, setSelectedStore] = useState()
  const [selectedPartner, setSelectedPartner] = useState()

  const {  register, handleSubmit, formState: { errors }, setValue, control } = useForm()

  let toastId;

  const { mutate: onSubmit } = useMutation(PostLink, {
    onSuccess: (data) => {
      toast.success(data?.message)
      toast.dismiss(toastId)
      queryClient.refetchQueries(['tablelinks'])
    },
    onError: (err) => {
      toast.dismiss(toastId)
    },
    onMutate: () => {
      toastId = toast.loading("Posting link")
    }
})

  useEffect(() => {
    setValue("storeName", "")
    setValue("storeCode", "")
    setValue("partnerCode", "")
    setValue("homeUrl", "")
    setValue("affiliateUrl", "")
    setValue("ratio", 20)
    setValue('active', false)
  }, [modalIsOpen])

  const handleStoreNameChange = (e) => {
    let thisSlug = slugify(e.target.value, {
      replacement: '-',
      lower: true,
      trim: true
    })
    setValue("storeCode",thisSlug)
  }

  return <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => {setIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Add Link"
          >
            <h2>Add Link</h2>
            
              <Form onSubmit={handleSubmit(onSubmit)}>
                <DropDownWrapper>
                    <Left>
                        <Input 
                          placeholder="Store Name*" 
                          {...register("storeName", {
                            required: "Enter Store name"
                          })}
                          onChange={handleStoreNameChange}
                        />
                        {errors.storeName && <p>{errors.storeName.message}</p>}
                    </Left>
                    <Right>
                        <Input 
                          placeholder="Store Code*" 
                          {...register("storeCode", {
                            required: "Enter Store code"
                          })}
                        />
                        {errors.storeCode && <p>{errors.storeCode.message}</p>}
                    </Right>
                </DropDownWrapper>

                <InputWrapper>
                    <Controller
                        control={control}
                        name="partnerCode"
                        {...register("partnerCode", {
                          required: "Select a Partner"
                        })}
                        onChange={(partner) => {setSelectedPartner(partner)}}
                        value={selectedPartner}
                        render={({field: {onChange, value}}) => (
                            <Select 
                              className="r-dropdown-links" 
                              options={partnerNames}  
                              placeholder="Select Partner" 
                              onChange={onChange} 
                              value={value}
                              isSearchable
                              styles={customStylesSelect}
                            />
                        )}
                    />
                    {errors.partnerCode && <p>{errors.partnerCode.message}</p>}
                </InputWrapper>
                

                <InputWrapper>
                    <Input 
                      placeholder="Home URL*" 
                      {...register("homeUrl", {
                        required: "Enter Home page Url"
                      })}
                    />
                    {errors.homeUrl && <p>{errors.homeUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Affiliate URL*" 
                      {...register("affiliateUrl", {
                        required: "Enter Affiliate Url"
                      })}
                    />
                    {errors.affiliateUrl && <p>{errors.affiliateUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Cart Page (optional)" 
                      {...register("mustPath")}
                    />
                    {errors.mustPath && <p>{errors.mustPath.message}</p>}
                </InputWrapper>

                <InputWrapper>
                    <Input 
                      placeholder="Ratio (optional)" 
                      {...register("ratio")}
                    />
                    {errors.ratio && <p>{errors.ratio.message}</p>}
                </InputWrapper>

                <CheckBoxWrapper>
                    <div>
                      <input type="checkbox" {...register("active")}/>
                      <label>Active</label>  
                    </div> 
                </CheckBoxWrapper>

                <input value="Add" type="submit"/>
              </Form>
            
          </Modal>
}

export default AddLinkModal
