const WAREHOUSE_URL = process.env.REACT_APP_WAREHOUSE_URL

const endpoints = {
    // DM Links
    PostDMLinkEndpoint: `${WAREHOUSE_URL}/dmlink/create`,
    GetDMLinksEndpoint: `${WAREHOUSE_URL}/dmlink/all`,
    GetDMLinkByIdEndpoint: `${WAREHOUSE_URL}/dmlink/one/`,
    UpdateDMLinkEndpoint:`${WAREHOUSE_URL}/dmlink/update`
}

export default endpoints