import { useState, useContext, useEffect } from 'react'
import axios from "axios"
import { serialize } from "object-to-formdata"

import Endpoints from '../config/endpoints'

import { GetTableValuesContext } from '../context/DealContext'

const { PostDealEndpoint, UpdateDealEndpoint, UploadDealsEndpoint, GetTableDealsEndpoint, GetDealByIdEndpoint, GetTableDealsWithFilterEndpoint } = Endpoints

const useDeal = (addToast) => {
  const [files, setFiles] = useState([])
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [selectedFilesLength, setSelectedFilesLength] = useState(0)

  const [loading, setLoading] = useState(false)


  const { setDeal,setDeals, setDealLastPage, setFilterApplied } = useContext(GetTableValuesContext)

  const docsPerPage = 20

  const PostDeal = async (data) => {
    try {
      setLoading(true)
      data = {
        ...data,
        actualPrice: parseInt(data.actualPrice),
        offerPrice: parseInt(data.offerPrice),
        storeCode: data.storeCode.value,
        categoryCode: data.categoryCode.value,
        productImage: data.productImage[0]
      }


      const formData = serialize(data);

      const response = await axios({
        method: 'POST',
        url: PostDealEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: formData
      })
      setLoading(false)
      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })
      getTableDeals()

    }catch(err){
      setLoading(false)
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const onSubmit = async () => {
    try {
      if(!files.length) {
        addToast("Select some files to upload", { 
          appearance: 'error',
          autoDismiss: true
        })
        return
      }
      
      const formData = new FormData()

      for(let i = 0; i < files.length; i++) {  
        formData.append('files', files[i]);
      }

      const response = await axios({
        method: 'POST',
        url: UploadDealsEndpoint,
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
        withCredentials: true,
        data: formData,
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(50)
        },
      })

      setUploadPercentage(100)
      setSelectedFilesLength(0)

      setTimeout(() => {
        setUploadPercentage(0)
      }, [100])

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableDeals()
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
      setUploadPercentage(100)
      setSelectedFilesLength(0)

      setTimeout(() => {
        setUploadPercentage(0)
      }, [100])
    }
  }

  const getTableDeals = async (page) => {
    try {
      const response = await axios({
        method: 'POST',
        url: GetTableDealsEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ page })
      })

      const deals = response.data.deals

      if(deals.length > 0) {
        let parsedArray = deals.map((cp) => {
          let resObj = {
            ...cp,
            store: cp.store.name,
            category: cp.category.label
          }

          return resObj
        })

        setDeals(parsedArray)
        setDealLastPage(Math.ceil(response.data.totalDealsCount / docsPerPage))
      }
    } catch(err) {

    }
  }

  const GetDealsWithFilter = async (data) => {
    try {
      const { storeCode } = data

      setFilterApplied(true)
      const response = await axios({
        method: 'POST',
        url: GetTableDealsWithFilterEndpoint,
        headers: {
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify({ 
          page: 1,
          storeCode: storeCode && storeCode.value, 
        })
      })

      const deals = response.data.deals

      if(deals.length > 0) {
        let parsedArray = deals.map((cp) => {
          let resObj = {
            ...cp,
            store: cp.store.name,
            category: cp.category.label
          }

          return resObj
        })

        setDeals(parsedArray)
        setDealLastPage(Math.ceil(response.data.totalDealsCount / docsPerPage))
      } else{
        setDeals([])
        setDealLastPage(1)
      }
    } catch(err) {
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  const GetDealById = async (dealId) => {
    try {
      if(!dealId) return;

      const response = await axios({
        method: 'GET',
        url: GetDealByIdEndpoint + dealId,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true
      })

      setDeal(response.data)
    } catch(err) {

    }
  }

  const UpdateDeal = async (data) => {
    try {
      data = {
        ...data,
        actualPrice: parseInt(data.actualPrice),
        offerPrice: parseInt(data.offerPrice),
        percentageOff: parseInt(data.percentageOff),
        storeCode: data.storeCode.value,
        categoryCode: data.categoryCode.value
      }

      const response = await axios({
        method: 'POST',
        url: UpdateDealEndpoint,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', 
        },
        withCredentials: true,
        data: JSON.stringify(data)
      })

      addToast(response.data.message, { 
        appearance: 'success',
        autoDismiss: true
      })

      getTableDeals()
    }catch(err){
      addToast(err.response.data.message, { 
        appearance: 'error',
        autoDismiss: true
      })
    }
  }


  return { PostDeal, UpdateDeal, GetDealById, onSubmit,  setFiles, uploadPercentage, selectedFilesLength, setSelectedFilesLength, getTableDeals, GetDealsWithFilter, loading }
}

export default useDeal

