import styled from "styled-components"

export const UploadArea = styled.div`
  width: 80%;

  flex: 1;
  margin: 0 auto;
  display: flex;

  div {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50px;
    

    h3 {
      margin-top: 1rem;
      color: ${props => props.theme.colors.darkGrey};
      font-size: 1rem;
    }
  }

  input {
    opacity: 0;
    flex: 1;
    /* background-color: pink; */
    :hover {
      cursor: pointer;
    }
  }
`;

export const Button = styled.button`
    border: none;
    outline: none;
    background-color: ${props => props.theme.colors.newGreen};
    color: ${props => props.theme.colors.white};
    /* border-radius: 3px; */
    padding: 0.4rem 0;

    :hover{
      cursor: pointer;
    }
`;

export const Form = styled.form`
  width: 100%;

  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  h4 {
    align-self: center;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.coa};
  }
`;