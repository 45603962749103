import styled from "styled-components"


export const InputWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    color: ${props => props.theme.colors.coa};
    align-self: flex-end;
  }

  textarea{
    width: 100%;
    height: 80px;
    outline: none;
    padding: 1rem;
    border: 1px solid;
  }
`;

export const Input = styled.input`
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
`;

export const DateWrapper = styled(InputWrapper)`
  flex-direction: row;
  align-items: center;
  width: 50%;
  justify-content: space-between;

  label{
    font-size: 1.1rem;
    color: ${props => props.theme.colors.darkGrey};
  }
`;

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  flex: 1;

  input[type="submit"] {
    border: none;
    outline: none;
    background-color: ${props => props.theme.colors.newGreen};
    color: ${props => props.theme.colors.white};
    align-self: flex-end;
    padding: 0.7rem 0;
    width: 20%;
    border-radius: 3px;

    :hover{
      cursor: pointer;
    }
  }
`;

export const DropDownWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    color: ${props => props.theme.colors.coa};
    align-self: flex-end;
  }
`;

export const Left = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const Right = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  label{
    display: flex;
    align-items: center;
  }
`;