import styled from "styled-components"

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  
  h1{
    color:${props => props.theme.colors.darkGrey};
    width: 80%;
  }

  div {
    width: 20%;
    display: flex;
    justify-content: space-between;
  }
`;

export const AddBtn = styled.button`
    outline: none;
    border: none;
    border: 1px solid ${props => props.theme.colors.darkGrey};
    padding: 0 1rem;
    border-radius: 3px;
    color: ${props => props.theme.colors.darkGrey};
    transition: 0.1s background-color ease-in;

    :hover{
      background-color: ${props => props.theme.colors.darkGrey};
      color: ${props => props.theme.colors.white};
    }
`;

export const TableContainer = styled.div`
  margin-top: 1rem;
`;

