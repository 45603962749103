import React from "react"
import { NavLink } from 'react-router-dom'

import SideNavMenu from '../data/sidenav.json'
import Header from "./components/header"

import { Container, HeaderDiv, SideNav, Content, LI } from './index.styled'

const Layout = ({children}) => {
  return <Container>
    <HeaderDiv>
      <Header />
    </HeaderDiv>
    <SideNav>
      <ul>
          {SideNavMenu.map((menuItem, index) => {
            return <LI key={index}> 
              <NavLink  activeStyle={{
                  boxShadow: "inset 0 0px 50px 12px rgb(255 255 255 / 10%)"
                }} key={index}  to={menuItem.path}>
                {menuItem.label}
              </NavLink>
          </LI>
          })}
      </ul>
    </SideNav>

    <Content>
      {children}
    </Content>
  </Container>
}

export default Layout