import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  align-items: flex-end;
`;

const Buttons = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  background-color: white;
  width: 40px;
  height: 25px;
  border: 1px solid #afafaf;
  margin: 0 0.5rem;
`;

const Jump = styled.div`
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Pagination = ({ canPreviousPage, canNextPage, pageCount, pageIndex, pageOptions, pageSize, gotoPage, previousPage, nextPage, setPageSize  }) => {
  return <Wrapper>
      <Buttons>
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>{"<"}</Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>{">"}</Button>
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</Button>
      </Buttons>
      
      <Jump>
        <span>Page {pageIndex + 1} of {pageOptions.length}</span>
        <span>Go to page:</span>
        <input
          type="number"
          value={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px" }}
        />

        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </Jump>
    </Wrapper>
}

export default Pagination