import React, { useEffect } from "react"
import { useTable, useGlobalFilter, usePagination} from 'react-table'
import { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'

import { TableActionButton, Pagination, Next, Prev } from "./index.styled"

import { GetTableValuesContext } from '../../../../context/CouponContext'

import useCoupon from "../../../../hooks/useCoupon"

import { GlobalFilter } from "./GlobalFilter"

const CouponsTable = () => {
  const { addToast } = useToasts()

  const { coupons, setUpdateModalIsOpen, setCouponId, pageCount, pageSize, setPageCount } = useContext(GetTableValuesContext)
  const { getTableCoupons } = useCoupon(addToast)

  const data = React.useMemo(() => coupons, [coupons])

  const columns = React.useMemo(() => [
    {
      Header: 'Title',
      accessor: 'title',
    },{
      Header: 'Coupon code',
      accessor: 'couponCode',
    },{
      Header: 'Store',
      accessor: 'store',
    },{
      Header: 'Category',
      accessor: 'category',
      disableGlobalFilter: true
    },{
      Header: 'Expires',
      accessor: 'expires',
      Cell: ({row}) => {
        return new Date(row.values.expires).toDateString() 
      }
    },{
      Header: 'Action',
      Cell: ({row}) => {
        return <TableActionButton onClick={() => {
          setCouponId(row.original.id)
          setUpdateModalIsOpen(true)
      }}>Edit</TableActionButton>
      }
    }
  ], [])

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups,  
    page, 
    nextPage, 
    previousPage,
    canNextPage, 
    canPreviousPage, 
    prepareRow , 
    state: { globalFilter, pageIndex }, 
    setGlobalFilter, 
  } = useTable({ 
    columns, 
    data,
    pageCount,
    manualPagination: true,
    autoResetSortBy: false,
    autoResetPage: false,
    initialState: { 
      pageSize 
    }
  }, useGlobalFilter, usePagination)

  useEffect(() => {
    const offset = pageIndex * pageSize
    getTableCoupons(pageIndex + 1, offset)
  }, [pageIndex])


  return <>
    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
    <table {...getTableProps()} className="content-table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {/* {rows.length === 0 && <h3 style={{textAlign: "center", margin: '1rem 0'}}>No data found</h3>} */}
        {page.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <Pagination>
      <span>
          <p>Page {pageIndex + 1} of {pageCount}</p>
      </span>
      <Prev onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Prev>
      
      <Next onClick={() => nextPage()} disabled={!canNextPage}>Next</Next>
    </Pagination>
  </>
  
}

export default CouponsTable