import React, { useEffect, useContext} from 'react'
import Modal from 'react-modal'
import { useForm, Controller } from "react-hook-form"
import DatePicker from 'react-date-picker'
import { useToasts } from 'react-toast-notifications'
import Select from 'react-select'

import { InputWrapper, Input, DateWrapper, Form, DropDownWrapper, Left, Right, CheckBoxWrapper } from './updateOfferModal.styled'

import useStore from '../../../../hooks/useStore'
import useCategory from '../../../../hooks/useCategory'
import usePoffer from '../../../../hooks/usePoffer'

import { GetTableValuesContext } from '../../../../context/POfferContext'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minHeight: '75%',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.55)'
  }
}

const customStylesSelect = {
  menu: (provided) => ({
    ...provided,
    maxHeight: '100px',
    overflow: 'auto'
  }),
  menuList: () => ({
    maxHeight: '100px'
  })
}

const UpdateOfferModal = ({ updateModalIsOpen, setUpdateModalIsOpen }) => {
  const { addToast } = useToasts()

  const { control, register, handleSubmit, formState: { errors }, setValue } = useForm()

  const { UpdateOffer, GetOfferById } = usePoffer(addToast)
  const { offerId, offer, storeNames, categoryNames } = useContext(GetTableValuesContext)

  useEffect(() => {
    GetOfferById(offerId)
  }, [updateModalIsOpen])

  useEffect(() => {
    if(offer) {
      const { id, title, description, detailDescription, storeCode, categoryCode, store, category, popular, verified, redirectUrl, score } = offer

      setValue('id', id)
      setValue('title', title)
      setValue('description', description)
      setValue('detailDescription', detailDescription)
      setValue("redirectUrl", redirectUrl)
      setValue("score", score)
      setValue('storeCode', {
        label: store.name,
        value: storeCode
      })
      setValue('categoryCode', {
        label: category.label,
        value: categoryCode
      })
      setValue('popular', popular)
      setValue('verified', verified)
    }
   }, [offer])

  return <Modal
            isOpen={updateModalIsOpen}
            onRequestClose={() => {setUpdateModalIsOpen(false)}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Update Offer"
          >
            <h2>Update Offer</h2>
            {!offer ? <p>Loading..</p> :            
              <Form onSubmit={handleSubmit(UpdateOffer)}>
                <InputWrapper>
                    <Input 
                      disabled
                      {...register("id", {
                        required: "Enter a offer id"
                      })}
                    />
                    {errors.id && <p>{errors.id.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="Offer Title*" 
                    {...register("title", {
                      required: "Enter a offer title"
                    })}
                  />
                  {errors.title && <p>{errors.title.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    placeholder="Offer Description*" 
                    {...register("description", {
                      required: "Enter a offer description"
                    })} 
                  />
                  {errors.description && <p>{errors.description.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <textarea 
                    placeholder="Offer details(separated by ;)*" 
                    {...register("detailDescription", {
                      required: "Enter offer details"
                    })} 
                  />
                  {errors.detailDescription && <p>{errors.detailDescription.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    placeholder="Redirect URL*" 
                    {...register("redirectUrl", {
                      required: "Enter a redirect url"
                    })}
                  />
                  {errors.redirectUrl && <p>{errors.redirectUrl.message}</p>}
                </InputWrapper>

                <InputWrapper>
                  <Input 
                    type="number"
                    placeholder="Score*" 
                    {...register("score", {
                      required: "Enter a score"
                    })}
                  />
                  {errors.score && <p>{errors.score.message}</p>}
                </InputWrapper>

                <DropDownWrapper>
                    <Left>
                      <Controller
                        control={control}
                        {...register("storeCode", {
                          required: "Select a store"
                        })}
                        render={({field: {onChange, value}}) => (
                            <Select 
                              className="r-dropdown" 
                              options={storeNames}  
                              placeholder="Select Store" 
                              onChange={onChange} 
                              value={value}
                              styles={customStylesSelect}
                              isSearchable
                            />
                        )}
                    />
                    {errors.storeCode && <p>{errors.storeCode.message}</p>}
                    </Left>
                    <Right>
                      <Controller
                          control={control}
                          name="categoryCode"
                          {...register("categoryCode", {
                            required: "Select a Category"
                          })}
                          render={({field: {onChange, value}}) => (
                              <Select 
                                className="r-dropdown" 
                                options={categoryNames}  
                                placeholder="Select Category" 
                                onChange={onChange} 
                                value={value}
                                styles={customStylesSelect}
                                isSearchable
                              />
                          )}
                      />
                      {errors.categoryCode && <p>{errors.categoryCode.message}</p>}
                    </Right>
                </DropDownWrapper>

                <CheckBoxWrapper>
                    <div>
                      <input type="checkbox" {...register("verified")}/>
                      <label>Verified</label>  
                    </div> 
                    <div>
                      <input type="checkbox" {...register("popular")}/>
                      <label>Popular</label>  
                    </div> 
                </CheckBoxWrapper>

                <input value="Update" type="submit"/>
              </Form>}
          </Modal>
}

export default UpdateOfferModal

