import React, {useContext, useState} from 'react'
import { useForm } from "react-hook-form"
import axios from 'axios'
import { useHistory } from 'react-router-dom'


import { Container, Input, LogoContainer, InputWrap, ErrorMessage, Content, Footer } from './index.styled'
import { Spinner } from '../../globalStyles'

import { AuthContext } from '../../context/AuthContext'
import Endpoints from '../../config/endpoints'

const { LoginAdmin } = Endpoints

const Login = () => {
    const [loading, setLoading] = useState(false)
  
    const history = useHistory()

    const { register, handleSubmit, formState: { errors } } = useForm()    
    const { setAuthState, errorMessage, setErrorMessage, getAdmin } = useContext(AuthContext)


    const handleLogin = async (data) => {
      try {
        setLoading(true)
  
        await axios({
          method: 'POST',
          url: LoginAdmin,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json', 
          },
          withCredentials: true,
          data: JSON.stringify(data)
        })
        
        await getAdmin()
        setLoading(false)
  
        history.push('/stores')
      } catch (err) {
        setLoading(false)
        setAuthState('loaded')
        setErrorMessage(err.response.data.message)
      }
    }

    return <Container>
      <Content>
        <LogoContainer>
          <img src="https://assets.dealmela.com/webassets/logo.svg" alt="logo"/>
        </LogoContainer>

        <form onSubmit={handleSubmit(handleLogin)}>
            <InputWrap>
              <Input 
                placeholder="Enter email"
                autoComplete="off"
                {...register("email", {
                    required: "You must specify an email",
                    pattern: {
                        value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm,
                        message: 'Enter a valid email'
                    }
                })}
              />
              <p>{errors.email && errors.email.message}</p>
            </InputWrap>

            <InputWrap>
              <Input
                type="password"
                name="password"
                placeholder="Enter password"
                autoComplete="off"
                {...register('password', {
                    required: "You must specify a password",
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters"
                    }
                })}
                />
              <p>{errors.password && errors.password.message}</p>
            </InputWrap>
            
          <button>{loading ? <Spinner /> : 'Login'}</button>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </form>
      </Content>
      <Footer>
          <h4>All rights reserved © 2021. Team DealMela</h4>
      </Footer>
    </Container>
}

export default Login