import React, { useMemo } from 'react'
import { useReducer, createContext, useContext } from 'react'

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 20,
  totalCount: null,
}


const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const TableReducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
  }
}

export const useTableReducer = () => {
  return useReducer(TableReducer, initialState)
}